import {mapGetters} from "vuex";

export default {
    watch: {
        CURRENT_PAGE() {
            this.stop();
        }
    },
    computed: {
        ...mapGetters([
            'CURRENT_PAGE'
        ]),
    },
    created() {
        this.start();
        setTimeout(() => {
            this.stop();
        }, 4000);
    },
    methods: {
        start() {
            this.$confetti.start({
                particlesPerFrame: 2,
                windSpeedMax: 1
            });
        },
        stop() {
            this.$confetti.stop();
        },
        love() {
            this.$confetti.update({
                particles: [
                    {
                        type: 'heart',
                    },
                    {
                        type: 'circle',
                    },
                ],
                defaultColors: [
                    'red',
                    'pink',
                    '#ba0000'
                ],
            });
        },
    }
}