<template>
    <lassoLayout>
        <div class="verification-request-submitted">
            <div class="content-page">
                <v-container>
                    <div class="block_is_this_you">
                        <h1>
                            Success!
                        </h1>
                        <div class="description">
                            Your claim has been submitted. <br>Please allow a few days for processing.
                        </div>
                        <div class="spaceship"></div>
                        <div class="action_buttons">
                            <button type="button" class="primary_btn x_small" @click="donate" v-ripple>Donate</button>
                            <router-link :to="{name: 'explore'}">Home</router-link>
                        </div>
                    </div>
                </v-container>
            </div>
        </div>
    </lassoLayout>
</template>

<script>
import lassoLayout from "@/layouts/lassoLayout.vue";
import confetti from '@/mixins/confetti.js';

export default {
    name: 'is-this-you',
    components: {
        lassoLayout
    },
    data() {
        return {}
    },
	mixins: [confetti],
    computed: {},
    mounted() {

    },
    methods: {
        donate() {
            window.open('https://www.patreon.com/psychurch', '_blank')
        }
    }
}
</script>
